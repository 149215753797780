import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { Button } from '@wbk/ui';
import OffsideWrapper from '@/components/error/OffsideWrapper';
import Reveal from '@/components/animation/Reveal';
import BouncingFootball from '@/components/svg/BouncingFootball';
import { GLOBAL_OPENER_DELAY } from '../constants/delay';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title={t('common:errors.404')} nofollow noindex />
      <OffsideWrapper>
        <div className='space-y-10 rounded-lg bg-secondary-400/40 p-4 py-8 text-center shadow-md'>
          <Reveal
            as='h1'
            delay={GLOBAL_OPENER_DELAY}
            direction='bottom'
            className='text-error-dark mb-6 text-7xl uppercase'
          >
            <strong>{t('common:errors.offside')}</strong>
          </Reveal>
          <h2 className='flex items-center justify-center gap-2 pb-4 text-[160px]'>
            <Reveal as='span' direction='top' delay={GLOBAL_OPENER_DELAY + 0.2}>
              4
            </Reveal>
            <BouncingFootball className='relative' width={110} height={110} delay={0.7} id='404' />
            <Reveal as='span' direction='top' delay={GLOBAL_OPENER_DELAY + 0.3}>
              4
            </Reveal>
          </h2>
          <Reveal as='p' direction='bottom' delay={GLOBAL_OPENER_DELAY + 0.4} className='text-lg'>
            {t('common:errors.404')}
          </Reveal>
          <Reveal direction='bottom' delay={GLOBAL_OPENER_DELAY + 0.6} className='mx-auto max-w-xs'>
            <Button as={Link} href='/' className='mt-6 w-full text-lg'>
              {t('common:go_home')}
            </Button>
          </Reveal>
        </div>
      </OffsideWrapper>
    </>
  );
};

export const getStaticProps: GetStaticProps<LayoutProps> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common', 'auth_common'])),
    },
  };
};

export default Page404;
